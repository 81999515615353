import React from 'react';
import loadable from '@loadable/component'
// import Layout from '../components/Layout';
// import Seo from '../components/Seo';
// import AnimationRevealPage from '../components/AnimationRevealPage';
// import SchoolFeatures from '../components/Features/SchoolFeatures';



const Seo = loadable(() => import('../components/Seo'));
const AnimationRevealPage = loadable(() => import('../components/AnimationRevealPage'));
const SchoolFeatures = loadable(() => import('../components/Features/SchoolFeatures'));
const Layout = loadable(() => import('../components/Layout'));



export default function Etablissement() {
  return (
    <AnimationRevealPage>
      <Layout>
        <Seo
          title="Collègé Jules Verne - L'établissement"
          description="N'hésitez pas à nous contacter pour toutes questions ou demande"
        />

        <SchoolFeatures />
      </Layout>
    </AnimationRevealPage>
  );
}
